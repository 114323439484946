import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PageInfoProps, PaginationContextProps, PublicationProps } from '../../types/pages'
import { ArticlePreviewWithAuthorAndCategoriesProps, CategoryProps } from '../../types/blog'
// Components
import { Box } from '../../atoms'
import { Layout, Seo } from '../../components/commons'
import { ArticlesHeaderWithCategories, BlogPaginatedArticles } from '../../components/blog'

interface DataQuery {
  blogUtils: {
    pageInfo: PageInfoProps
    allArticlesLabel: string
    articlesListLabel: string
    paginationPreviousLabel: string
    paginationNextLabel: string
  }
  category: CategoryProps & {
    locale: string
    meta: PublicationProps
  }
  categoryList: {
    nodes: CategoryProps[]
  }
  articlesList: {
    nodes: ArticlePreviewWithAuthorAndCategoriesProps[]
  }
}

interface PageContext extends PaginationContextProps {
  categoryId: string
}

const Category: React.FC<PageProps<DataQuery, PageContext>> = ({ pageContext, data }) => {
  const { totalPages, currentPage, previousPagePath, nextPagePath } = pageContext
  const {
    blogUtils: {
      pageInfo: blogPageInfo,
      allArticlesLabel,
      articlesListLabel,
      paginationPreviousLabel,
      paginationNextLabel,
    },
    category: { locale, meta, pageInfo },
    categoryList: { nodes: categories },
    articlesList: { nodes: articles },
  } = data

  return (
    <Layout headerDark={pageInfo.headerDark}>
      <Seo slug={pageInfo.slug} publication={meta} lang={locale} content={pageInfo.seo} />

      <Box as={'section'} pt={[100, 120]}>
        <ArticlesHeaderWithCategories
          title={articlesListLabel}
          blogLandingPageInfo={blogPageInfo}
          allLabel={allArticlesLabel}
          categories={categories}
          isAll={false}
        />
      </Box>

      <BlogPaginatedArticles
        articles={articles}
        prevLabel={paginationPreviousLabel}
        previousPagePath={previousPagePath}
        nextLabel={paginationNextLabel}
        nextPagePath={nextPagePath}
        totalPages={totalPages}
        currentPage={currentPage}
      />
    </Layout>
  )
}

export const query = graphql`
  query CategoryQuery($categoryId: String!, $skip: Int!, $limit: Int!) {
    blogUtils: datoCmsBlog {
      pageInfo {
        ...pageInfoFragment
      }
      allArticlesLabel
      articlesListLabel
      paginationPreviousLabel
      paginationNextLabel
    }
    category: datoCmsCategory(id: { eq: $categoryId }) {
      locale
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      ...categoryFragment
    }
    categoryList: allDatoCmsCategory(sort: { fields: name }) {
      nodes {
        ...categoryFragment
      }
    }
    articlesList: allDatoCmsArticle(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___firstPublishedAt }
      filter: { categories: { elemMatch: { id: { eq: $categoryId } } } }
    ) {
      nodes {
        ...articlePreviewWithAuthorAndCategoriesFragment
      }
    }
  }
`

export default Category
